<template>
  <div class="mx-auto flex max-w-5xl justify-start gap-3 pb-8">
    <!-- <Select
      v-if="radiosItems.length > 1"
      :value="selection.radio?.value"
      name="radio"
      :options="radiosItems"
      button-style=" bg-neutral-800 px-3 py-2 rounded-lg h-full"
      list-style="bg-white rounded-lg mt-2 border border-neutral-800 p-2"
      option-style="hover:bg-neutral-800/5 text-neutral-800 p-2 rounded-lg"
      class="flex-1 dt:min-w-[255px] dt:flex-none"
      @change="
        (value) => {
          selection.radio = value
        }
      " /> -->

    <FormInput
      v-model="selection.keywords"
      type="text"
      name="keywords"
      icon="search-line"
      :placeholder="$t('NavbarSearchPanel.search')"
      input-style="rounded-lg outline-none px-3 py-2 bg-neutral-800"
      icon-style="!left-3"
      clearable-style="!right-3"
      clearable
      class="w-full flex-1"
      @enter-pressed="handleSubmit" />

    <SquareButton
      :dark="false"
      icon="search-line"
      @click="handleSubmit"
      icon-extra-class="text-lg" />
  </div>
</template>
<script setup lang="ts">
const localePath = useLocalePath()
const config = useRuntimeConfig()
const route = useRoute()
const router = useRouter()

const {
  searchPanelVisible,
  selectionPrettyString,
  selection,
  radiosItems,
  typeItems,
  fetchData,
  initialize
} = useNavbarSearchPanel()

await fetchData()
initialize()

watch(route, initialize)

const type = ref(route.query.type)
const radio = ref(route.query.radio)
const keywords = ref(route.query.keywords)
const category = ref(route.query.category)

const query = computed(() => {
  return {
    radio: selection.value.radio?.value,
    type: selection.value.type?.value,
    keywords: selection.value.keywords
  }
})
watch(
  keywords,
  (value) => {
    selection.value.keywords = value
  },
  { immediate: true }
)
const handleSubmit = () => {
  // if not type, select first type
  if (!selection.value.type) {
    selection.value.type = typeItems.value[0]
  }
  router.push(
    localePath({
      name: 'search',
      query: query.value
    })
  )
}

onMounted(() => {
  // by default, select BOA radio
  if (!selection.value.radio) {
    selection.value.radio = radiosItems.value.find(
      (i) => i.value === config.public.radioBoaSlug
    )
  }
})
</script>
